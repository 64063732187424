
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  【中古車】MT車で人気のホンダのS660・日産のシルビアの特徴と相場を解説！
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            ホンダのS660と日産のシルビアは、MT車好きにはたまらない魅力が数多くある車種です。お気に入りの一台を探すために、人気の2車種の特徴と中古での相場を把握しておきましょう。中古車をお探しでしたら、car shop MIXをご利用ください。
            <div class="section-title">
              <span >S660やシルビアの特徴・相場をご紹介！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      MT車として人気のホンダのS660と日産のシルビアの特徴、中古市場での相場価格についてご紹介いたします。
                    </p>
                    <h3 class="heading-number" data-number="◆">ホンダ「S660」の特徴と価格相場</h3>
                    <p class="u-m0 u-pb1em">
                      「S660」はホンダが誇る軽スポーツカーで、そのコンパクトなボディとMR（ミッドシップエンジン・リアドライブ）のレイアウトが生み出す走行性能が魅力の車種です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      S660はホンダの「楽しい」を追求した結果生まれたモデルで、その名のとおり660ccのエンジンを中心に設計されています。660㏄という小さなエンジンからは想像できないほどのパフォーマンスを引き出せるのが特徴です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      さらに、操作性のよさと、パワーを最大限に引き出せる6速MTが特徴で、緻密なコントロールを楽しめます。
                    </p>
                    <p class="u-m0 u-pb1em">
                      また、エンジンの位置や車体の設計などの絶妙なバランスが、スポーツカーとしての性能を実現しています。タウンユースとしての利便性もありながら、サーキットでの走行も楽しめる車です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      オープンカーとしての楽しさを味わえるのも特徴です。風を切って走る感覚は一度味わうと忘れられない体験となるでしょう。
                    </p>
                    <p class="u-m0 u-pb1em">
                      S660はインテリアにもこだわって製造されています。スポーティーでありながらも高級感があり、運転席に座るとまるでレーシングカーに乗っているような感覚を味わえます。また、安全装備も充実していますので「初めてスポーツカーに挑戦する方でも安心して乗れること」も高ポイントです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      S660の中古車市場での相場価格は、状態や年式、走行距離によりますが、150～400万円程度が一般的です。現在は生産が終了しているため、その希少性からさらに価格が上昇する傾向にあります。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/red-sports-car-2.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">日産「シルビア」の特徴と価格相場</h3>
              <p class="u-m0 u-pb1em">
                日産の「シルビア」は、FR（フロントエンジン・リアドライブ）のレイアウトと、美しいボディラインが特徴のスポーツカーです。1965年に誕生した初代シルビアですが、クーペボディの販売不振と排ガス規制によって2002年に生産が終了しています。
              </p>
              <p class="u-m0 u-pb1em">
                シルビアはその生産期間中にモデルチェンジを行っており、それぞれのモデルで特徴が異なります。その中でも特に人気のあるS15と呼ばれるモデルは、中古車市場でも需要があります。
              </p>
              <p class="u-m0 u-pb1em">
                シルビアはドリフトカルチャーの象徴と称されており、操作性と乗り心地のよさは世界中のドライバーから賞賛を受けています。また、カスタマイズの幅広さも人気を誇る理由の1つです。
              </p>
              <p class="u-m0 u-pb1em">
                30代から40代の自動車が好きな方や、海外のスポーツカーファンの間では今でもシルビアの人気は高く、中古車業界では高額で取引されています。中古市場におけるシルビアの価格相場は状態やモデルによりますが、一般的には約100～300万円が目安です。人気のS15型は価格がさらに高騰傾向にあります。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                人気のMT車をお探しの方は、car shop MIXまでご相談ください。car shop MIXは、低走行の車種や25年規制が解除されるような価値の高い車種を集めるノウハウがございます。国産車だけでなく、外車も含めて様々なMT車を取り揃えています。ご希望の車種がございましたら、お気軽にお問い合わせください。
              </p>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >S660とシルビアの中古車をお探しならcar shop MIXへ</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      ホンダのS660と日産のシルビアは、日本国内に限らず、全世界にファンを持つ車種であり、中古市場でも根強い人気を誇ります。
                    </p>
                    <p class="u-m0 u-pb1em">
                      もちろん、車の状態や年式などによって販売価格は大きく異なりますが、100～400万円程度が相場と考えてください。予算と相談しながら、ぴったりの一台を探してみてはいかがでしょうか。
                    </p>
                    <p class="u-m0 u-pb1em">
                      少しでも状態のよい中古車を選ぶためには、取り扱う車種や在庫が豊富な販売店を探すことが大切です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      ホンダのS660と日産のシルビアなどの人気のMT車を購入したいとお考えの方は、茨城県坂東市にあるcar shop MIXにお任せください。中古の高級車をはじめ、人気のMT車種など幅広いジャンルの車を豊富に取り揃えています。
                    </p>
                    <p class="u-m0 u-pb1em">
                      お目当ての車がない場合も、豊富なネットワークを活かして、ご希望の車種をお探しします。人気のMT車に関する疑問などにも誠実にお答えいたしますので、ぜひお気軽にお問い合わせください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="S660とシルビアの中古車について案内するイメージ" src="@/assets/img/column-maker-mt/s660-used-car-guide-image.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/jigyo" class="c-btn-anchor">事業内容</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'ホンダのS660や日産のシルビアなどの中古車・MT車をお探しならcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-07.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
